export const LAYOUT_SET_HEAD = 'LAYOUT_SET_HEAD';
export const LAYOUT_SET_FOOTER_DATA = 'LAYOUT_SET_FOOTER_DATA';
export const LAYOUT_SET_FOOTER_VARIANT = 'LAYOUT_SET_FOOTER_VARIANT';
export const LAYOUT_SET_HEADER_DATA = 'LAYOUT_SET_HEADER_DATA';
export const LAYOUT_SET_HEADER_LITE = 'LAYOUT_SET_HEADER_LITE';
export const LAYOUT_SET_HEADER_CTA = 'LAYOUT_SET_HEADER_CTA';
export const LAYOUT_SET_CANONICAL_URL = 'LAYOUT_SET_CANONICAL_URL';
export const LAYOUT_SET_HEADER_CURRENT = 'LAYOUT_SET_HEADER_CURRENT';
export const LAYOUT_SET_HEADER_PRO_DATA = 'LAYOUT_SET_HEADER_PRO_DATA';
export const LAYOUT_SET_BREADCRUMBS = 'LAYOUT_SET_BREADCRUMBS';
export const LAYOUT_SET_PRO = 'LAYOUT_SET_PRO';
export const LAYOUT_SET_NAV_OPEN_INDEX = 'LAYOUT_SET_NAV_OPEN_INDEX';
export const SETTINGS_SET_BASE_URL = 'SETTINGS_SET_BASE_URL';
export const SETTINGS_SET_LOCALES = 'SETTINGS_SET_LOCALES';
export const LAYOUT_SET_ALT_LANGUAGES = 'LAYOUT_SET_ALT_LANGUAGES';
export const TRACKING_SET_DATA = 'TRACKING_SET_DATA';
export const LAYOUT_SET_DEVICE = 'LAYOUT_SET_DEVICE';
export const LAYOUT_LISA_AUTOFILL = 'LAYOUT_LISA_AUTOFILL';
export const EKOMI_SET_REVIEWS = 'EKOMI_SET_REVIEWS';
export const EKOMI_SET_ANSWERS = 'EKOMI_SET_ANSWERS';
export const LAYOUT_SET_TOP_HEADER = 'LAYOUT_SET_TOP_HEADER';
